import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ListDossiersService } from 'app/services/list-dossiers.service';
import { PreviewService } from 'app/services/preview.service';
import { ActivatedRoute } from '@angular/router';
import { SuccessMessageComponent } from 'app/shared/success-message/success-message.component';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-Echeancier',
  templateUrl: './Echeancier.component.html',
  styleUrls: ['./Echeancier.component.css']
})
export class EcheancierComponent implements OnInit {
  selectedFolder: string;
  minDate: Date;
  hour = 0;
  minute = 0;
  hourValue = "00";
  minuteValue = "00";
  pipe = new DatePipe('fr-FR');
  CustomDate: string; // Declare the CustomDate property
  dureeRemboursePar: string; // Declare the dureeRemboursePar

  @Output() reloadData = new EventEmitter();
  reload: string;

  New_Echeancier_Form = new FormGroup({
    periodicite: new FormControl("", [Validators.required, Validators.minLength(3)]),
    creancePrincipal: new FormControl("", [Validators.required, Validators.minLength(3)]),
    dureeRemboursement: new FormControl("", [Validators.required]),
    tauxInteret: new FormControl("", [Validators.required]),
    tauxInteretRetard: new FormControl("", [Validators.required]),
    datePremiereEcheancier: new FormControl("", [Validators.required, Validators.minLength(3)]),
    heure: new FormControl("", [Validators.required, Validators.minLength(3)]),
  });

  constructor(
    private dossiers: ListDossiersService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private api: PreviewService,
    public dialogRef: MatDialogRef<EcheancierComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    const currentDate = new Date();
    this.minDate = new Date(currentDate);
    this.dureeRemboursePar = ""; //

  }

  ngOnInit(): void {
    this.selectedFolder = this.data.selectedFolder;
    console.log('folder inf', this.selectedFolder);
  }

  public save() {
    this.New_Echeancier_Form.value.heure = this.hourValue + ':' + this.minuteValue;
    console.log(this.New_Echeancier_Form.value.heure);
    var NewDate = new Date(this.New_Echeancier_Form.value.datePremiereEcheancier);
    NewDate.setDate(new Date(this.New_Echeancier_Form.value.datePremiereEcheancier).getDate());
    NewDate.toLocaleDateString();
    this.CustomDate = this.pipe.transform(NewDate, 'yyyy-MM-dd');
    this.New_Echeancier_Form.value.datePremiereEcheancier = this.CustomDate + 'T' + this.New_Echeancier_Form.value.heure + ':00Z';
    console.log("NewDate : "+ NewDate);
    console.log("CustomDate : "+ this.CustomDate);
    console.log("this.New_Echeancier_Form.value.datePremiereEcheancier : "+ this.New_Echeancier_Form.value.datePremiereEcheancier);
    const NewEcheancier = {
      periodicite: this.New_Echeancier_Form.value.periodicite,
      creancePrincipal: this.New_Echeancier_Form.value.creancePrincipal,
      dureeRemboursement: this.New_Echeancier_Form.value.dureeRemboursement,
      tauxInteret: this.New_Echeancier_Form.value.tauxInteret,
      tauxInteretRetard: this.New_Echeancier_Form.value.tauxInteretRetard,
      datePremiereEcheancier: this.New_Echeancier_Form.value.datePremiereEcheancier,
      dureeRemboursePar: this.dureeRemboursePar,
    };

    console.log(NewEcheancier);
    this.dossiers.CreateEcheancier(NewEcheancier, this.selectedFolder).subscribe({
      complete: () => {
        console.log('Echeancier successfully created!');
        this.OpenSuccessDialog();
        this.reloadData.emit(this.reload);
        this.dialogRef.close();
      },
      error: (e) => {
        console.log(e);
        this.api.OpenEchecDialog();
      },
    });
  }

  public OpenSuccessDialog() {
    this.dialog.open(SuccessMessageComponent, {
      width: '600px',
      height: '300px',
      data: {
        title_label: 'Success',
        sub_title_label: 'Echeancier has been successfully added',
        button_label: 'Ok',
        success_icon: true,
        echec_icon: false
      }
    });
  }

  colsePopup() {
    this.dialogRef.close();
  }

  echeancier: string[] = ['Trimestrielle', 'Mensuelle', 'Annuelle'];

  onChange(event: any) {
  console.log(event);

    const selectedValue = event.value;
    if (selectedValue === "Trimestrielle") {
      this.dureeRemboursePar = "Trimestre";
      console.log(selectedValue);
    } else if (selectedValue === "Mensuelle") {
      this.dureeRemboursePar = "Mois";
      console.log(selectedValue);
    } else if (selectedValue === "Annuelle") {
      this.dureeRemboursePar = "Années";
      console.log(selectedValue);
    }
  }

  hour_up() {
    this.hour++;
    if (this.hour == 0) {
      this.hourValue = '00';
    } else if (this.hour > 23) {
      this.hour = 0;
    } else if (this.hour < 10 && this.hour > 0) {
      this.hourValue = '0' + this.hour;
    } else {
      this.hourValue = this.hour.toString();
    }
  }

  hour_down() {
    this.hour--;
    if (this.hour == 0) {
      this.hourValue = '00';
    } else if (this.hour < 1) {
      this.hour = 24;
    } else if (this.hour < 10 && this.hour > 0) {
      this.hourValue = '0' + this.hour;
    } else {
      this.hourValue = this.hour.toString();
    }
  }

  minute_up() {
    this.minute++;
    if (this.minute == 0) {
      this.minuteValue = '00';
    } else if (this.minute > 59) {
      this.minute = 0;
    } else if (this.minute < 10 && this.minute > 0) {
      this.minuteValue = '0' + this.minute;
    } else {
      this.minuteValue = this.minute.toString();
    }
  }

  minute_down() {
    this.minute--;
    if (this.minute == 0) {
      this.minuteValue = '00';
    } else if (this.minute < 1) {
      this.minute = 60;
    } else if (this.minute < 10 && this.minute > 0) {
      this.minuteValue = '0' + this.minute;
    } else {
      this.minuteValue = this.minute.toString();
    }
  }
}
