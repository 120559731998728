<section>
  <form [formGroup]="New_Saisine_Form">

    <div class="d-flex justify-content-between">

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Nom de saisine</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Nom de saisine" type="text" formControlName="nomsaisine" />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Région</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Région" formControlName="region">
              <mat-option *ngFor="let docs of Type" [value]="docs">{{ docs }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Type de tiers</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Type de tiers" formControlName="typeDeTiers">
              <mat-option *ngFor="let docs of Autre" [value]="docs">{{ docs }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Nom de tiers</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Nom de tiers" type="text" formControlName="nomDeTiers" />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Pièce jointe</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <button mat-fab color="accent" aria-label="Déposer un fichier"  matTooltip="Déposer un fichier" (click)="openModal()">
            <mat-icon>cloud_upload</mat-icon>
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-center col-md-1">
        <a (click)="save()" type="submit"><img src="./assets/plus.png" class="icon" /></a>
      </div>

    </div>

  </form>
</section>
