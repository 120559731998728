<section>
  <form [formGroup]="New_Versement_Form">
    <div class="d-flex justify-content-between">

    <div class=" col-md-4">
      <label class="title "><p class="fnt-size fw-bold px-2">Date</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput [matDatepicker]="picker" placeholder="Date d’effet des IR"
            formControlName="dateVersement">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class=" col-md-4">
      <label class="title"><p class="fnt-size fw-bold px-2">Réference Versement</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Réference Versement" type="text" formControlName="referenceVersement" />
        </mat-form-field>
      </div>
    </div>

    <div class=" col-md-4">
      <label class="title"><p class="fnt-size fw-bold px-2">Type Versement</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Type Versement" type="text" formControlName="typeVersement" />
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="d-flex justify-content-between">
    <div class=" col-md-4">
      <label class="title"><p class="fnt-size fw-bold px-2">Montant</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Montant" type="text" formControlName="montantVersement" />
        </mat-form-field>
      </div>
    </div>

    <div class=" col-md-4">
      <label class="title"><p class="fnt-size fw-bold px-2">Mode De Réglement</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-select placeholder="ModeReglement" formControlName="modeReglement">
            <mat-option *ngFor="let docs of mode" [value]="docs">{{
              docs
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <div class=" col-md-3">
      <label class="title"><p class="fnt-size fw-bold px-2">Affectation</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-select placeholder="Affectation" formControlName="affectaion">
            <mat-option *ngFor="let docs of affectation" [value]="docs">{{
              docs
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-1 d-flex justify-content-center">
      <a (click)="save()" type="submit"><img src="./assets/plus.png" class="icon"/></a>
    </div>

  </div>
  </form>
</section>
