<div class="px-3 ">

  <app-list-dossiers-directeur   [hidden]="session == 'GROUP_FINANCIERS'"></app-list-dossiers-directeur>

  <app-list-dossiers-financier [hidden]="session !== 'GROUP_FINANCIERS'"></app-list-dossiers-financier>

</div>








