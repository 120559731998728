<div class="py-3 px-4 text-center">
  <div class="titre py-5">
    <h2 mat-dialog-title class="fw-bold">
      Êtes-vous sûr de signaler le dossier
    </h2>
    <h4> Merci de vérifier votre boîte de messagerie</h4>
  </div>
  <br>
  <br>
  <br>
  <div>

  <button mat-button  mat-dialog-close="true" class="annuler-btn" >Annuler</button>
   &nbsp;
   &nbsp;
   &nbsp;
   &nbsp;
   &nbsp;
  <button mat-button  (click)="openDialogSignialerDossier()" class="ok-btn"> Signaler
  </button>

  </div>
</div>

