import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ListDossiersService } from 'app/services/list-dossiers.service';
import { ListDossiersFinancierService } from 'app/services/list-dossiers-financier.service';

@Component({
  selector: 'app-restantDu-modal',
  templateUrl: './restantDu-modal.component.html',
  styleUrls: ['./restantDu-modal.component.css']
})
export class RestantDuModalComponent implements OnInit {
  public error: boolean = false;
  public erreur: string = '';
  public versement: boolean;
  public nomVersement: string;
  public nomDossier: string;
  public selectedRestantDu: string;
  public lastRestantDu: string;
  public FormData: FormData;
  public button_label_1: string = '';
  public button_label_2: string = '';
  public title_label: string = '';
  public restantDuValue: any;
  public restantDu_Form: FormGroup; // Declare FormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public RestantDuModal: MatDialogRef<RestantDuModalComponent>,
    private dossiers: ListDossiersService,
    private financierService: ListDossiersFinancierService,
  ) {}

  ngOnInit(): void {
    this.title_label = this.data.title_label;
    this.button_label_1 = this.data.button_label_1;
    this.button_label_2 = this.data.button_label_2;
    this.nomDossier = this.data.nomDossier;
    this.versement = this.data.versement;
    this.nomVersement = this.data.nomVersement;
    this.getVersementByName(); // Fetch restantDuValue asynchronously
    console.log(this.nomDossier);

    // Initialize the form group
    this.restantDu_Form = new FormGroup({
      restantDu: new FormControl(this.restantDuValue, [Validators.required]), // Set initial value to null
    });
  }

  getVersementByName() {
    this.financierService.GetVersementByName(this.nomDossier, this.nomVersement).subscribe((data) => {
      this.restantDuValue = data.restantDu;
      // After getting the value, update the form control value
      this.restantDu_Form.controls['restantDu'].setValue(this.restantDuValue);
      console.log(this.restantDuValue);
      console.log(data.restantDu);
    })
  }

  onSubmitRestantDu() {
    if (this.restantDu_Form.invalid && (this.restantDu_Form.dirty || this.restantDu_Form.touched)) {
      this.error = true;
      this.erreur = "Veuillez saisir des données correctes";
      console.log("erreur", this.erreur);
    } else {
      this.RestantDuModal.close(this.restantDu_Form.value); // Close dialog and send form values
    }
  }

  closePopup() {
    this.RestantDuModal.close();
  }
}
