<section>
  <form [formGroup]="updateForm">
    <div class="d-flex justify-content-between">
      <div class="form-group col-md-2">
        <label class="title">Périodicite</label>
        <div class="d-inline-block mb-2 text-dark mt-2 w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Créances" formControlName="periodicite">
              <mat-option *ngFor="let docs of echeancier" [value]="docs">{{
                docs
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title">Creance principal</label>
        <div class="d-inline-block mb-2 text-dark mt-2 w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Creance principal"
              type="text"
              formControlName="creancePrincipal"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title">Durée remboursement</label>
        <div class="d-inline-block mb-2 text-dark mt-2 w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Durée remboursement"
              type="text"
              formControlName="dureeRemboursement"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title">Taux intérêt</label>
        <div class="d-inline-block mb-2 text-dark mt-2 w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Taux intérêt"
              type="text"
              formControlName="tauxInteret"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title">Intérêt du retardt</label>
        <div class="d-inline-block mb-2 text-dark mt-2 w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Intérêt du retard"
              type="text"
              formControlName="tauxInteretRetard"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title">Date 1ere echéance</label>
        <div class="d-inline-block mb-2 text-dark mt-2 w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput [matDatepicker]="picker" placeholder="Date 1ere echéance"
              formControlName="datePremiereEcheancier" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-2 d-flex justify-content-center">
        <a (click)="onSubmit()" type="submit"
          ><img src="./assets/plus.png" class="icon"
        /></a>
      </div>
    </div>
  </form>
</section>
