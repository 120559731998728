
<div class="d-flex justify-content-between">
  <mat-card-subtitle class="pt-2 pb-2 fw-bold text-dark">{{title_label}}</mat-card-subtitle>
  <!-- <mat-button class="pointer" (click)="openModal()"  matTooltip="Déposer un fichier"><mat-icon class="icon-blue-color" >cloud_upload</mat-icon></mat-button> -->
</div>
  <form [formGroup]="restantDu_Form">
    <div class="alert alert-danger" role="alert" *ngIf="error">
      {{erreur}}
    </div>
    <div class="d-flex justify-content-center pt-4">
      <div class="px-2">
        <div class="alert alert-dark" role="alert">
          <strong style="color: #000000 !important;">{{this.restantDuValue}} CDF</strong>
        </div>
      </div>
      </div>

</form>

<div class="d-flex justify-content-center pt-4">

  <div class="px-4">
    <button mat-button class="annuler-btn" (click)="closePopup()">{{button_label_1}}</button>
  </div>

<div class="px-4">
    <button mat-button class="ok-btn" (click)="onSubmitRestantDu()">{{button_label_2}}</button>
 </div>
</div>
