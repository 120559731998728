<adf-toolbar>
  <adf-toolbar-title>
    <adf-breadcrumb
      class="files-breadcrumb"
      root="Personal Files"
      [target]="documentList"
      [folderNode]="documentList"
    >
    </adf-breadcrumb>
  </adf-toolbar-title>

  <div class="adf-toolbar--spacer"></div>

  <adf-upload-button
    [rootFolderId]="documentList.currentFolderId"
    [adf-check-allowable-operation]="'create'"
    (success)="uploadSuccess()"
  >
  </adf-upload-button>
</adf-toolbar>

<adf-document-list
  #documentList
  [currentFolderId]="currentFolderId"
  (preview)="showPreview($event)"
  (nodeDblClick)="clickedNodeId($event)"
>
</adf-document-list>
<!------------------------------------- test ----------------------------------------->

<adf-pagination
  [target]="documentList"
  [supportedPageSizes]="[5, 10, 15, 20]"
  [pagination]="10"
>
</adf-pagination>

<adf-file-uploading-dialog></adf-file-uploading-dialog>
