<div class="px-3">

      <form style="line-height: 5px;" class="row" [formGroup]="New_Tiers_Form" >

          <div class="d-flex justify-content-between">
            <div class="d-inline-block mb-2 text-dark mt-2 ">
              <mat-card-title >Nouveau tiers</mat-card-title>
            </div>
            <div class="d-flex">
              <div class="d-inline-block mb-2 text-dark px-2">
                <button mat-button  type="submit"  class="save-btn" (click)="save()" (click)="resetInput()">Enregistrer</button>
              </div>
            </div>
          </div>
          <br>
          <mat-card>
          <mat-card-subtitle class="py-4 text-dark fw-bold ">Informations génerales</mat-card-subtitle >
          <div class="row">
            <div class="col-md-3">
              <div>
              <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.nom.errors && submitted">Nom</span></label>
              <label *ngIf="f.nom.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red"> Veuillez entrer un nom </label>
                <mat-form-field appearance='outline' class="w-100">
                  <input matInput placeholder="Nom" type="text" formControlName="nom">
                  <mat-icon *ngIf="f.nom.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
                </mat-form-field>
              </div>

            </div>
            <div class="col-md-3">
              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.prenom.errors && submitted">Prénom</span></label>
                <label *ngIf="f.prenom.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer un prénom </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="Prénom" type="text" formControlName="prenom">
                <mat-icon *ngIf="f.prenom.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
              </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.cin.errors && submitted">CIN</span></label>
                <label *ngIf="f.cin.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer un CIN de 8 chiffres </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="CIN" type="text" formControlName="cin" (keypress)="keyPress($event)">
                <mat-icon *ngIf="f.cin.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
              </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.adresse.errors && submitted">Adresse</span></label>
                <label *ngIf="f.adresse.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer une adresse </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="Adresse" type="text" formControlName="adresse">
                <mat-icon *ngIf="f.adresse.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
              </mat-form-field>
              </div>
            </div>
            <div class="col-md-3 d-flex justify-content-between">

                <div class="col-md-6 px-1">
                  <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.codePostale.errors && submitted">Code postal</span></label>
                  <label *ngIf="f.codePostale.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Code postal de 4 chiffres </label>
                  <mat-form-field appearance='outline' class="w-100" >
                  <input matInput placeholder="Code postale" type="text" formControlName="codePostale" (keypress)="keyPress($event)">
                  <mat-icon *ngIf="f.codePostale.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
                </mat-form-field>
                </div>

                <div class="col-md-6 px-1">
                  <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.ville.errors && submitted">Ville</span></label>
                  <label *ngIf="f.ville.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer une ville </label>
                  <mat-form-field appearance='outline' class="w-100" >
                  <input matInput placeholder="Ville" type="text" formControlName="ville">
                  <mat-icon *ngIf="f.ville.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
                </mat-form-field>
                </div>

              </div>

            <div class="col-md-3">

              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.gouvernorat.errors && submitted">Gouvernorat</span></label>
                <label *ngIf="f.gouvernorat.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer un gouvernorat </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="Gouvernorat" type="text" formControlName="gouvernorat">
                <mat-icon *ngIf="f.gouvernorat.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
              </mat-form-field>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.numeroFax.errors && submitted">Fax</span></label>
                <label *ngIf="f.numeroFax.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer un numero Fax de 8 chiffres </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="Fax" type="text" formControlName="numeroFax" (keypress)="keyPress($event)">
                <mat-icon *ngIf="f.numeroFax.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
               </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.numeroFix.errors && submitted">Numéro de Téléphone Fixe</span></label>
                <label *ngIf="f.numeroFix.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red"><span class=""></span> entrer un numero fixe de 8 chiffres </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="Téléphone Fixe" type="text" formControlName="numeroFix" (keypress)="keyPress($event)">
                <mat-icon *ngIf="f.numeroFix.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
               </mat-form-field>
              </div>

            </div>
            <div class="col-md-3">
                <div class="form-group">
                  <label class=" fw-bold pb-2 fnt-11">Numéro de téléphone</label>
                  <!-- <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.telephone.errors && submitted">Numéro de téléphone</span></label> -->
                  <!-- <label *ngIf="f.telephone.errors && submitted" [hidden]="key" class="fw-bold pb-2 fnt-11 text-red">Veuillez entrer un numéro de téléphone de 8 chiffres </label> -->
                  <div class="d-inline-block text-dark w-100 px-1 mt-1">
                    <div>
                      <div class="editor-tag" (click)="focusTagInput()" >
                        <span class="tag-item" *ngFor="let tag of tags">
                          {{tag}}
                          <span class="remove-tag" (click)="removeTag(telephone)">×</span>
                        </span>
                             <input
                              placeholder="Téléphone"
                              #tagInput type="text"
                              class="input-tag"
                              (keyup)="onKeyUp($event)"
                              formControlName="telephone"
                              (keypress)="keyPress($event)"
                              >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-2 px-1 position-absolute">
                  <div class="alert alert-primary " role="alert" *ngIf="phoneValidationText">
                      <p>NB: Veuillez vérifier que le numéro téléphone</p>
                      <p>contient seulement 8 chiffres</p>
                  </div>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div>
                <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.email.errors && submitted">E-mail</span></label>
                <label *ngIf="f.email.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer une adresse email </label>
                <mat-form-field appearance='outline' class="w-100" >
                <input matInput placeholder="E-mail" type="text" formControlName="email">
                <mat-icon *ngIf="f.email.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
              </mat-form-field>
              </div>
            </div>

            <div class="col-md-3">
              <label class=" fw-bold pb-2 fnt-11"><span [hidden]="f.type.errors && submitted">Type de tiers</span></label>
              <label *ngIf="f.type.errors && submitted" class=" fw-bold pb-2 fnt-11 text-red">Veuillez entrer un type </label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select placeholder="Type de tiers" formControlName="type" >
                  <mat-option *ngFor="let g of tiers" [value]="g" type="text" >
                    {{ g }}
                  </mat-option>
                </mat-select>
                <mat-icon *ngIf="f.type.errors && submitted" matSuffix  class="text-red">error_outline</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <br>
          <br>
        </mat-card>
      </form>

</div>



