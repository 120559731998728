<div>
  <div class="d-flex">
    <div class="col-md-6">
      <adf-login [logoImageUrl]="'./../../assets/pencil.png'" successRoute="/home"></adf-login>
    </div>
    <div class="col-md-6">
      <img src="./assets/login.png" class="w-100 h-100">
    </div>
  </div>
</div>
