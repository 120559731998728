<div class="d-flex justify-content-between">
  <mat-card-subtitle class="pt-2 pb-2 fw-bold text-dark">Remplire Echeancie</mat-card-subtitle>
  <!-- <mat-button class="pointer" (click)="openModal()"  matTooltip="Déposer un fichier"><mat-icon class="icon-blue-color" >cloud_upload</mat-icon></mat-button> -->
</div><br>
  <form [formGroup]="New_Echeancier_Form">
     <div class="d-flex align-items-center">
      <div class="col-md-11">
        <div class="px-1">
        <label class="title">Périodicite</label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Périodicite" formControlName="periodicite" (selectionChange)="onChange($event)">
              <mat-option *ngFor="let docs of echeancier" [value]="docs">{{
                docs
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="col-md-11">
        <div class="px-1">
        <label class="title">Créance En Principal</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Créance en principal"
              type="text"
              formControlName="creancePrincipal"
            />
          </mat-form-field>
        </div>
      </div>
      <p class="ml-2">CDF</p>
    </div>
    <div class="d-flex align-items-center">
      <div class="col-md-11">
        <div class="px-1">
        <label class="title">Durée De Remboursement</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Durée de remboursement"
              type="text"
              formControlName="dureeRemboursement"

            />
          </mat-form-field>
        </div>
      </div>
      <p class="ml-2">({{this.dureeRemboursePar}})</p>
    </div>
    <div class="d-flex align-items-center">
      <div class="col-md-11">
        <div class="px-1">
        <label class="title">Taux D'Intérêt</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              placeholder="Taux d'Intérêt"
              type="text"
              formControlName="tauxInteret"
            />
          </mat-form-field>
        </div>
      </div>
      <p class="ml-2">%</p>
    </div>
  <div class="d-flex align-items-center">
    <div class="col-md-11">
      <div class="px-1">
      <label class="title">Intérêt De Retard</label>
        <mat-form-field appearance="outline" class="w-100">
          <input
            matInput
            placeholder="Taux intérêt de retard"
            type="text"
            formControlName="tauxInteretRetard"
          />
        </mat-form-field>
      </div>
    </div>
    <p class="ml-2">%</p>
  </div>
  <div class="d-flex align-items-center">
    <div class="col-md-11">
      <div class="px-1">
      <label class="title">Date 1ere Echéance</label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput [matDatepicker]="picker" placeholder="Date 1ere echéance"
          formControlName="datePremiereEcheancier" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
    <div class="d-flex justify-content-center pt-2">

      <div class="px-2">
        <button mat-button class="annuler-btn" (click)="colsePopup()">Annuler</button>
      </div>

      <div class="px-2">

      <div>
        <button mat-button class="ok-btn" (click)="save()" type="submit">Ok</button>
      </div>

      </div>

  </div>

