// popup-pdf.component.ts
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewService } from 'app/services/preview.service';
import { ListDossiersService } from 'app/services/list-dossiers.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ListDossiersFinancierService } from 'app/services/list-dossiers-financier.service';
import { AuthenticationService } from '@alfresco/adf-core';
import { environment } from 'environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentListService } from '@alfresco/adf-content-services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-PopupPDFComponent',
  templateUrl: './PopupPDFComponent.component.html',
  styleUrls: ['./PopupPDFComponent.component.css']
})
export class PopupPDFComponent implements OnInit {
  public minDate: Date;
  public montant:string;
  public phase: string;
  public baseUrl=`${environment.baseUrl}`
  public telecharger: string;
  public nomDossier:string;
  public nomVersement:string;
  public ticket=`?alf_ticket=${this.authService.getTicketEcm()}`
  public voirPdfFile: string;
  public voirPdfFileSafe: SafeResourceUrl;
  urlDuPDF: string;
  versement: any;
  public pdfSrc: any;
  public pdfUrl: string; // URL of the PDF
  public pdfBlob: Blob; // Blob
  public pdfData: any;
  public pdfResult :any
  public pdfContent: any; // PDF content received from the server
  public Data: any; // Data received from the server
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupPDFComponent>,
    private api:PreviewService,
    private financierService :ListDossiersFinancierService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private dossiers :ListDossiersService,
    private authService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,

  ) {}

  ngOnInit(): void {
    this.nomDossier = this.data.nomDossier;
    this.nomVersement = this.data.nomVersement;
    console.log(this.nomDossier)
    console.log(this.nomVersement)
    this.GetVersementByName();
  }

  GetVersementByName(): void {
    this.financierService.GetVersementByName(this.nomDossier, this.nomVersement).subscribe((data) => {
      this.pdfUrl = this.baseUrl + data.telecharger + this.ticket;
      this.fetchPdfContent();
    });
  }

  fetchPdfContent(): void {
    this.http.get(this.pdfUrl, { responseType:  'blob' as 'json'}).subscribe(
      (pdfBlob: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            const base64Data = btoa(reader.result as string); // Convert to base64
            this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
              `data:application/pdf;base64,${base64Data}`
            );
            this.pdfResult = this.pdfSrc.changingThisBreaksApplicationSecurity

          }
        };
        reader.readAsDataURL(pdfBlob); // Read blob as data URL
        this.pdfData = true;
      },
      (error) => {
        console.error('Error fetching PDF content:', error);
      }
    );
  }

  fermerPopup(): void {
    this.dialogRef.close();
  }
}

