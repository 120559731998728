<div *ngIf="!pdfData" style="height:100%;width:100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
  <mat-spinner></mat-spinner>
  <p style="margin-top: 16px; color: #194b89;">Loading, veuillez patienter...</p>
</div>
<div *ngIf="pdfData" style="height:100%;width:100%">
  <button mat-button style="border-radius: 50px; background-color: #194b89; color: #ffff;" (click)="fermerPopup()"> X </button>
    <pdf-viewer [src]="pdfUrl" [render-text]="true" style="display: block;"></pdf-viewer>  <mat-dialog-actions class="border-top" style="display: flex; justify-content: center; width: 100%;">
    <div style="padding-bottom: 10px; padding-right: 10px;">
      <a *ngIf="pdfData" [href]="" class="link-download gap-2" style="color: #194b89 !important; background-color: #ffff !important; padding-right: 10px;" (click)="fermerPopup()"> Cancel <mat-icon class="action-icon ml-2">cancel</mat-icon></a>
    </div>
    <div style="padding-bottom: 10px;">
      <a *ngIf="pdfData" [href]="pdfUrl" class="link-download gap-2"> Télécharger <mat-icon class="action-icon ml-2">cloud_download</mat-icon></a>
    </div>
  </mat-dialog-actions>
</div>
