<div class="px-3">
  <div>

      <div class="d-flex justify-content-between pb-5">
        <div><mat-card-title>Demande spécifique</mat-card-title></div>
        <div> <button mat-raised-button color="primary" class="action">Enregistrer</button></div>
      </div>

      <br>
      <mat-card>
        <div>
            <div>
                <div>
                  <div>
                    <label class="title pt-4">Numéro de compte</label>
                  </div>

                    <div >
                      <mat-form-field appearance="outline" class=" w-25">
                        <mat-select placeholder="Numéro de compte">
                          <mat-option *ngFor="let num of numbersList" [value]="numbers">{{num}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <label class="title">Liste des documents demandés</label>

                    <div>
                      <mat-form-field appearance="outline" class=" w-25">
                        <mat-select placeholder="Liste des documents demandés">
                          <mat-option *ngFor="let docs of documentsList" [value]="docs">{{docs}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                </div>
            </div>
        </div>
    </mat-card>
  </div>
  </div>


