<div class='px-3'>
  <div class='d-flex justify-content-between pb-5'>

      <div>
        <mat-card-title>Liste des chargés </mat-card-title>
      </div>

      <div class='d-flex justify-content-between'>


           <div class='d-flex justify-content-between'>
            <div class="px-2">
              <button mat-stroked-button class="annuler-btn" type='submit' routerLink='/nouv-charge' >Nouveau chargé</button>
            </div>
            <div class="px-2">
              <button mat-button class="ok-btn" [matMenuTriggerFor]='menu' >Action</button>

              <mat-menu #menu='matMenu' class="mt-2">
              <button  mat-menu-item (click)='openRemplacerDialog()' >Remplacer un chargé</button>
              <button  mat-menu-item (click)='openDeleteDialog()'>Supprimer un chargé</button>
              </mat-menu>
            </div>



          </div>

      </div>
  </div>
  <div class='example-container mat-elevation-z8 '>
    <mat-table #table [dataSource]='charges' matSort>

        <ng-container matColumnDef='select'>
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)='$event ? masterToggle() : null'
                        [checked]='selection.hasValue()'
                        [indeterminate]='selection.hasValue()'>
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef='let row'>
          <mat-checkbox (click)='$event.stopPropagation()'
                        (change)='$event ? selection.toggle(row) : null'
                        [checked]='selection.isSelected(row)' >

          </mat-checkbox>
        </mat-cell>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef='id'>
         <mat-header-cell *matHeaderCellDef>Code chargé </mat-header-cell>
         <mat-cell *matCellDef='let charges'><p class="fnt-10 mt-3">{{charges.id}}</p></mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef='firstName'>
         <mat-header-cell *matHeaderCellDef> Nom chargé</mat-header-cell>
         <mat-cell *matCellDef='let charges'><p class="fnt-10 mt-3">{{charges.firstName}}  {{charges.lastName}}</p></mat-cell>
        </ng-container>

        <!-- Adresse  Column -->
        <ng-container matColumnDef='adresse'>
         <mat-header-cell *matHeaderCellDef> Adresse </mat-header-cell>
         <mat-cell *matCellDef='let charges'><p class="fnt-10 mt-3"> {{charges.adresse}}</p> </mat-cell>
        </ng-container>

        <!-- Montant Column -->
        <ng-container matColumnDef='telephone'>
          <mat-header-cell *matHeaderCellDef> Numéro de téléphone</mat-header-cell>
          <mat-cell *matCellDef='let charges'><p class="fnt-10 mt-3"> {{charges.telephone}} </p></mat-cell>
        </ng-container>

        <!-- Montant Column -->
        <ng-container matColumnDef='gouvernorat'>
          <mat-header-cell *matHeaderCellDef> Gouvernorat </mat-header-cell>
          <mat-cell *matCellDef='let charges'><p class="fnt-10 mt-3"> {{charges.gouvernorat}}</p>  </mat-cell>
        </ng-container>

        <!-- Montant Column -->
        <ng-container matColumnDef='ville'>
          <mat-header-cell *matHeaderCellDef>Ville </mat-header-cell>
          <mat-cell *matCellDef='let charges'> <p class="fnt-10 mt-3">{{charges.ville}}</p> </mat-cell>
        </ng-container>

        <!-- Montant Column -->
        <ng-container matColumnDef='disponibilite'>
           <mat-header-cell *matHeaderCellDef> Disponibilité </mat-header-cell>
            <mat-cell *matCellDef='let charges'>
              <button class="staus-btn" mat-button [hidden]="charges.disponibilite == 'false'"  [style.background-color]="'#009432'">Disponible</button>
              <button class="staus-btn" mat-button [hidden]="charges.disponibilite == 'true'"  [style.background-color]="'#EA2027'">Non Dispoible</button>
            </mat-cell>
        </ng-container>

        <!-- Montant Column -->
        <ng-container matColumnDef='dateDeLaProchaineDisponibilite'>
          <mat-header-cell *matHeaderCellDef>Prochaine disponibilité </mat-header-cell>
          <mat-cell *matCellDef='let charges'>
            <span [hidden]="charges.disponibilite == 'true'" >
            <p [hidden]="charges.motif == 'depart'" class="mt-3 fnt-10">{{charges.dateDeLaProchaineDisponibilite}}</p>
            </span>
          </mat-cell>
        </ng-container>

        <!-- Montant Column -->
        <ng-container matColumnDef='motif'>
          <mat-header-cell *matHeaderCellDef>Motif d'indisponibilité</mat-header-cell>
          <mat-cell *matCellDef='let charges'><p [hidden]="charges.disponibilite == 'true'" class="mt-3">
            <span [hidden]="charges.motif !=='depart'" class="fnt-10 mt-3">Départ</span>
            <span [hidden]="charges.motif !=='conge'" class="fnt-10 mt-3">Congé</span>
            <span [hidden]="charges.motif !=='maladie'" class="fnt-10 mt-3">Maladie</span>
          </p></mat-cell>
        </ng-container>

        <!--Signaler action Column -->
         <ng-container matColumnDef='modifier'>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef='let charges'><button mat-button class='consulter-btn staus-btn' type='button' routerLink='/update-charge/{{charges.firstName}}'>Modifier</button></mat-cell>
        </ng-container>


      <mat-header-row *matHeaderRowDef='displayedTabColumns'></mat-header-row>
      <mat-row *matRowDef='let row; columns: displayedTabColumns;'></mat-row>

    </mat-table>
    <mat-paginator [pageSizeOptions]='[7, 10, 25, 100]'></mat-paginator>
  </div>
  </div>

