<section>
  <form [formGroup]="updateForm">

  <div class="d-flex justify-content-between">

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Type</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Nature" formControlName="typeGar">
              <mat-option *ngFor="let docs of Type" [value]="docs">{{
                docs
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Nature</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-select placeholder="Créances" formControlName="natureHypotheque">
              <mat-option *ngFor="let docs of  Hypotheque" [value]="docs">{{
                docs
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Rang</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Montant" type="text" formControlName="rang" />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group col-md-3">
        <label class="title"><p class="fnt-size fw-bold">Immatriculation</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Immatriculation" type="text" formControlName="immatriculation" />
          </mat-form-field>
        </div>
      </div>


      <div class="form-group col-md-2">
        <label class="title"><p class="fnt-size fw-bold">Date fin de l’hypothèque</p></label>
        <div class="d-inline-block text-dark w-100 px-1">
          <!-- <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Montant" type="text" formControlName="datefinhypotheque" />
          </mat-form-field> -->
          <mat-form-field appearance="outline" class="w-100">
            <input matInput [matDatepicker]="picker" placeholder="Date d’effet des IR"
              formControlName="datefinhypotheque">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

  </div>

  <div class="d-flex justify-content-between">

    <div class="form-group col-md-2">
      <label class="title"><p class="fnt-size fw-bold">Montant de l’hypotèque</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Date d’effet des IR" type="text" formControlName="montanthypotheque" />
        </mat-form-field>
      </div>
    </div>

    <div class="form-group col-md-3">
      <label class="title"><p class="fnt-size fw-bold">Valeur estimée de l’enquête</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Montant" type="text" formControlName="valeurestimee" />
        </mat-form-field>
      </div>
    </div>

    <div class="form-group col-md-3">
      <label class="title"><p class="fnt-size fw-bold">Hypotèque autres banques</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-select placeholder="Nature" formControlName="hypothequebanque">
            <mat-option *ngFor="let docs of Autre" [value]="docs">{{
              docs
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group col-md-3">
      <label class="title"><p class="fnt-size fw-bold">Bénéficiaire de l’hypotèque</p></label>
      <div class="d-inline-block text-dark w-100 px-1">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Montant" type="text" formControlName="beneficiairehypotheque" />
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-1 d-flex justify-content-center">
      <a (click)="onSubmit()" type="submit"
        ><img src="./assets/plus.png" class="icon"
      /></a>
    </div>

  </div>

  </form>
  </section>
